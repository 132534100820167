// JS dependencies
var bootstrapselect = require('bootstrap-select');

// CSS dependency
import '/../node_modules/bootstrap-select/dist/css/bootstrap-select.css';

// Initialisation
$('.selectpicker').each(function(){
  var isSearch = $(this).hasClass('livesearch');
  var isSearchActive;
  isSearch ? isSearchActive = true : isSearchActive = false;
  $(this).selectpicker({
    liveSearch: isSearchActive
  });
})

$('.selectpicker').on('changed.bs.select', function(e){
  $(this).parent().addClass('changed');
});
