// Header Search
function nav_search(){
  var container = $('#header .form-search-wp');
  var input = $('#header .input');
  var btn = $('#header .btn-wp');
  var btn_txt = $('#header .btn-wp .txt');
  var isempty = true;
  input.hide();
  input.on('change past keyup', function(){
    var val = $(this).val().length;
    if(val>2){
      isempty = false;
    }
  });
  btn.on('click',function(){
    container.toggleClass('open');
    input.toggle();
    container.hasClass('open') ? input.focus() : null;
    !isempty ? container.trigger('submit') : null;
  });
}


function f_smoothscroll(target){
  $(target).on('click', function(event) {
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
        window.location.hash = hash;
      });
    }
  });
}

// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#nav-main').toggle();
    $('html, body').animate({ scrollTop: 0 }, '500');
  });

  var viewport = $(window).width();
  var articlenav_btn = $('#trigger-nav-article-wp');
  var articlenav_wrapper = articlenav_btn.next();
  var articlenav_link = articlenav_wrapper.find('.link');
  f_smoothscroll(articlenav_link);

  if(viewport < 768 ){

    articlenav_btn.on('click',function(){
      $(this).hide()
      articlenav_wrapper.show();
      return false;
    });
    articlenav_link.on('click', function(){
      articlenav_btn.show()
      articlenav_wrapper.hide();
    });
  }
}

// Navigation size
function header_small() {
  var articlenav = $('#nav-article-wp');
  var articlenav_height;
  articlenav.length ? articlenav_height = articlenav.innerHeight() : articlenav_height = 0;
  var header_height = $('#header').innerHeight() + articlenav_height;
  var navtop_height = $("#nav-top").innerHeight();
  function f_smallActive(){
    $('body').addClass('header-small').css('paddingTop',header_height);
    $('#header').css('marginTop',-navtop_height);
  }
  function f_smallInactive(){
    $('body').removeClass('header-small').css('paddingTop',0);
    $('#header').css('marginTop',0);
  }
  // On load
  $(window).scrollTop()>navtop_height ? f_smallActive() : f_smallInactive();
  // On scroll
  $(window).scroll(function() {
    $(this).scrollTop()>navtop_height ? f_smallActive() : f_smallInactive();
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    function f_reset(target){
      $('#nav-main li[data-navsub] > a').removeClass('open');
      $('#nav-main li[data-navsub] .nav-sub').hide();
      target ? target.addClass('open').next().toggle() : null;
    }
    $('#nav-main li[data-navsub] > a').on('click', function() {
      var isOpen = $(this).hasClass('open');
      isOpen ? f_reset() : f_reset($(this));
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function() {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      !isFocus ? navSub.addClass('focus') : null;
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}


function nav_fixed(){
  var nav = $('#filter-nav');
  var news = nav.hasClass('except-news');
  var news_height;
  news ? news_height = $('#header-news').innerHeight() : news_height = 0;

  var nav_position = $('#header').innerHeight() + $('.header-page-wp').innerHeight() + $('#filter-title').innerHeight() - 35 + news_height;

  function f_fixedActive(){
    $('body').addClass('fixed-nav');
  }
  function f_fixedInactive(){
    $('body').removeClass('fixed-nav');
  }

  $(window).scrollTop()>nav_position ? f_fixedActive() : f_fixedInactive();
  // On scroll
  $(window).scroll(function() {
    $(this).scrollTop()>nav_position ? f_fixedActive() : f_fixedInactive();
  });


}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    $(this).scrollTop() > 220 ? $('#btn-back-top').fadeIn(300) : $('#btn-back-top').fadeOut(300);
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({ scrollTop: 0 }, '500');
    return false;
  });
}

function input_change(){
  var input = $('.form-control');
  input.on('change past keyup', function(){
    var val = $(this).val().length;
    val>2 ? $(this).addClass('filled') : $(this).removeClass('filled');
  });
}

// Builder Toggle
function builder_toggle(){
  var wrapper = $('.builder-toggle');
  var i = 0;
  wrapper.each(function(e){
    i++;
    var id = 'toggle-bluider-'+i;
    $(this).attr('id',id);
    var btn = $('#'+id+' .toggle-trigger');
    var target = $('#'+id+' .toggle-target');
    target.hide();
    btn.on('click',function(){
      target.show();
      var viewport = $(window).width();
      var target_offset = target.offset().top;
      var target_offset_final;
      viewport>576 ? target_offset_final = target_offset - 100 : target_offset_final = target_offset - 40;
      setTimeout(function(){
          $('html, body').animate({ scrollTop: target_offset_final}, '500');
        }, 200);
      return false;
    });
  });
}

function is_os(){
  var _isOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/);
  if (_isOS) {
    $('body').addClass('is-os');
  }
}

function f_searchform(){
  $('.trigger-reset').on('click',function(){
    $('#filter-nav .selectpicker').selectpicker('val', 0);
    $('#filter-nav .bootstrap-select').removeClass('changed');
  });
  $('.small-reset').on('click',function(){
    $(this).next().trigger('click');
    return false
  });
}

// Initialisation
nav_search();
nav_mobile();
nav_sub_menu();
header_small();
nav_fixed();
scroll_top();
builder_toggle();
input_change();
is_os();
f_searchform();
$(window).on('resize', function(){
  header_small();
  nav_mobile();
})
