// JS dependency
var stickykit = require('sticky-kit/dist/sticky-kit.js');

// Initialisation
function stickykit_init() {
  var viewport = $(window).width();
  if (viewport > 576) {
    var offset_header = 65;
    var offset_final;
    var nav = $("#nav-article-wp").length;
    var nav_height = $("#nav-article-wp").innerHeight();
    nav ? offset_final = offset_header + nav_height : offset_final = offset_header;

    $("#sticky-wp").stick_in_parent({
      offset_top: offset_final
    });
  } else {
    $("#sticky-wp").trigger("sticky_kit:detach");
  }
}

stickykit_init();

$(window).on('resize', function() {
  stickykit_init();
});
