// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

function f_slickNews(){
  $('#slick-news').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: false,
    arrows: true,
    autoplay: false,
    adaptiveHeight: true,
    cssEase: 'ease-in-out',
    speed: 400,
    autoplaySpeed: 6000,
    appendArrows: '#slick-news-arrows',
    prevArrow: '<button class="arrow-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button class="arrow-next"><i class="fas fa-chevron-right"></i></button>',
  });

  setTimeout(function() {
    $('#slick-news .slick-active').addClass('animation');
  }, 250);

  $('#slick-news').on('afterChange', function(event, slick, currentSlide) {
    $('#slick-news .slick-active').addClass('animation');
  });

  $('#slick-news').on('beforeChange', function(event, slick, currentSlide) {
    $('#slick-news .slick-active').removeClass('animation');
  });
}

f_slickNews();
