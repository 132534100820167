var cookie = require('js-cookie');

// Detect first visit
var target = $('#cookie-consent-wp');
cookie.get('cookie-consent')=='yes' ? target.hide() : target.show();

// Close
var close = target.find('.close');
close.on('click', function(){
  cookie.set('cookie-consent', 'yes', { expires: 365 });
  target.hide();
})
