// jQuery assets
import '/javascript/partials/jquery.js';
import '/javascript/partials/jquery-magnificpopup.js';
import '/javascript/partials/jquery-slick.js';
import '/javascript/partials/jquery-stickykit.js';
import '/javascript/partials/jquery-waypoints.js';

// Bootstrap assets
import '/javascript/partials/bootstrap.js';
import '/javascript/partials/bootstrap-selectpicker.js';

// Google assets
import '/javascript/partials/google-font.js';

// Other assets
import '/javascript/partials/aos.js';
import '/javascript/partials/js-cookie.js';
import '/javascript/partials/font-awesome.js';
import '/fonts/pixeden/font-pixeden.css';

// Modules
import '/javascript/modules/01-navigation.js';
import '/javascript/modules/02-helper.js';
import '/javascript/modules/03-components.js';

// SCSS
import '/scss/global.scss';
